.BoxFormControl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  .Label {
    width: 35%;
  }
  .BoxInput {
    width: 65%;
  }

  .itemOn {
    background-color: tan;
    
  }
}

.ButtonOutline {
  border: 1px solid black;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: white;

  border: 1px solid var(--active-color-hex);

  display: flex;
  align-items: center;

  font-size: 13px;
  font-weight: bold;
  color: var(--active-color-hex);

  &.Big {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-size: 16px;
  }

  &.Disabled {
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  span {
    display: block;
    margin-right: 7px;
    font-size: 16px;
    padding-bottom: 1px;
  }
  cursor: pointer;
}
.ButtonNormal {
  border: 1px solid black;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: var(--active-color-hex);

  border: 1px solid var(--active-color-hex);

  display: flex;
  align-items: center;

  font-size: 13px;
  font-weight: bold;
  color: white;
  cursor: pointer;

  &.Iconed {
    svg {
      font-size: 10px;
    }
  }

  &.Disabled {
    cursor: not-allowed;
    opacity: 50%;
  }

  svg {
    color: white;
  }

  span {
    display: block;
    margin-right: 7px;
    font-size: 16px;
    padding-bottom: 1px;
  }
}

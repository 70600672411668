.InputField {
  width: 100%;
  background-color: white;

  outline: none;
  border: 1px solid rgba(var(--theme-color-primary-rgb), 0.3);
  padding: 10px 14px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  &:focus {

    border: 1px solid rgba(var(--active-color-rgb), 0.6);
    -webkit-box-shadow: 0px 0px 1px 2px rgba(var(--active-color-rgb),0.5);
    -moz-box-shadow: 0px 0px 1px 2px rgba(var(--active-color-rgb),0.5);
    box-shadow: 0px 0px 1px 2px rgba(var(--active-color-rgb),0.5);
  }
}

.InputRadio {
  width: 18px;
  height: 18px;

  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;

  position: relative;

  &.Selected {
    border: 2px solid rgba(var(--active-color-rgb), 0.8);
    -webkit-box-shadow: 0px 0px 0px 10px rgba(var(--active-color-rgb), 0.1);
    -moz-box-shadow: 0px 0px 0px 10px rgba(var(--active-color-rgb), 0.1);
    box-shadow: 0px 0px 0px 10px rgba(var(--active-color-rgb), 0.1);
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background-color: var(--active-color-hex);
      border-radius: 100%;
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }

  &:hover {
    border: 2px solid rgba(var(--active-color-rgb), 0.6);
    -webkit-box-shadow: 0px 0px 0px 10px rgba(var(--active-color-rgb), 0.1);
    -moz-box-shadow: 0px 0px 0px 10px rgba(var(--active-color-rgb), 0.1);
    box-shadow: 0px 0px 0px 10px rgba(var(--active-color-rgb), 0.1);
  }
}

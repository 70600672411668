:root {
  //   --active-color-hex: #00c1d4;
  //   --active-color-rgb: 0, 193, 212;
  // --active-color-hex: #5fc4f9;
  // --active-color-rgb: 95, 196, 249;
  // --active-color-hex: #8c55ff;
  // --active-color-rgb: 140, 85, 255;
  // --active-color-hex: #f2bc65;
  // --active-color-rgb: 242, 188, 101;
  // --active-color-hex: #ff7f4f;
  // --active-color-rgb: 255, 127, 79;
  // --active-color-hex: #5e77f9;
  // --active-color-rgb: 94, 119, 249;

  --theme-bg-primary-hex: #1f212a;
  --theme-bg-primary-rgb: 31, 33, 42;

  --theme-bg-secondary-hex: #252731;
  --theme-bg-secondary-rgb: 37, 39, 49;

  --theme-color-title-primary: #ffffff;
  --theme-color-title-secondary: #ffffff;

  --theme-bc-primary-hex: #252731;
  --theme-bc-primary-rgb: 37, 39, 49;

  --theme-color-primary-hex: #8691a4;
  --theme-color-primary-rgb: 134, 145, 164;

  --theme-color-secondary-hex: #727c8f;
  --theme-color-secondary-rgb: 114, 124, 143;
}
